<template>
    <div style="padding:10px">
        <el-row class="text_right">
            <div v-if="!editFlag">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增</el-button>
                <el-button type="primary" size="small" icon="el-icon-edit" @click="editFlag = true">批量修改</el-button>
                <el-button type="danger" size="small" icon="el-icon-delete" @click="del()">批量删除
                </el-button>
            </div>
            <div v-else>
                <el-button size="small" @click="cancel()">取消</el-button>
                <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
            </div>
        </el-row>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="500px"
                @selection-change="selectionChangeDictHandle"
                class="table">
            <el-table-column
                    type="selection"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column prop="label" label="标签"></el-table-column>
            <el-table-column prop="value" label="键值"></el-table-column>
            <el-table-column prop="sort" label="排序">
                <template slot-scope="scope">
                    <span v-if="!editFlag">{{scope.row.sort}}</span>
                    <el-input-number :min="1" :max="9999" v-model="scope.row.sort" size="mini" v-else></el-input-number>
                </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    :key="Math.random()"
                    header-align="center"
                    align="center"
                    width="150"
                    label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="edit(scope.row.id)" :disabled="editFlag">修改</el-button>
                    <el-button type="text" size="small" @click="del(scope.row.id)" :disabled="editFlag">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <!-- 弹窗, 新增 / 修改 -->
        <dict-value-form ref="dictValueForm"
                         @refreshDataList="refreshList"></dict-value-form>
    </div>
</template>

<script>
    import DictValueForm from './DictValueForm'

    export default {
        data() {
            return {
                dataList: [],
                dictTypeId: '',
                dataListSelections: [],
                loading: false,
                editFlag: false,
            }
        },
        props: ['dictTypeTitle'],
        components: {
            DictValueForm
        },
        methods: {
            // 获取数据列表
            refreshList(dictTypeId) {
                this.loading = true
                if (dictTypeId) {
                    this.dictTypeId = dictTypeId
                }
                this.$axios(this.api.auth.dictGetDictValue, {
                    'dictTypeId': this.dictTypeId
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data
                        this.loading = false
                    }
                })
            },
            // 多选
            selectionChangeDictHandle(val) {
                this.dataListSelections = val
            },
            // 新增
            add() {
                this.$nextTick(() => {
                    this.$refs.dictValueForm.init('add', {dictValueId: '', dictTypeId: this.dictTypeId})
                })
            },
            // 确定
            doSubmit() {
                this.loading = true
                this.$axios(this.api.auth.updateDictValue, this.dataList, 'post').then(data => {
                    if (data.status) {
                        this.$message.success('操作成功')
                        this.editFlag = false
                    } else {
                        this.$message.error('操作失败')
                    }
                    this.refreshList(this.dictTypeId)
                })
            },
            cancel() {
                this.editFlag = false
                this.refreshList(this.dictTypeId)
            },
            // 修改
            edit(id) {
                this.$nextTick(() => {
                    this.$refs.dictValueForm.init('edit', {dictValueId: id, dictTypeId: this.dictTypeId})
                })
            },
            // 删除
            del(id) {
                if(!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.auth.dictDeleteDictValue, {ids: ids}, 'delete').then(data => {
                        if (data && data.status) {
                            this.$message.success(data.data)
                            this.refreshList()
                            this.$dictUtils.refreshDictList()
                        }
                    })
                })
            },
            closeRight() {
                this.$emit('closeRight')
            }
        }
    }
</script>
